<template lang="pug">
  .permissions-table.container-fluid
    .permissions-table-header.row
      .permissions-table-cell.col-sm-3 {{ $t("account_management.permission_settings.features_permissions") }}
      .permissions-table-cell.col-sm-3.justify-content-center {{ $t("account_management.permission_settings.edit") }}
      .permissions-table-cell.col-sm-3.justify-content-center {{ $t("account_management.permission_settings.view") }}
      .permissions-table-cell.col-sm-3.justify-content-center {{ $t("account_management.permission_settings.no_permission") }}
    .body
      .permissions-table-item.row(v-for="page in orderedPages")
        .permissions-table-cell.col-sm-3 {{ $t(`header.title.${page.name}`) }}
        .permissions-table-cell.col-sm-3.d-flex.justify-content-center.align-items-center
          AppCheckbox.highlight-checked(
            :value="isSelected(PERMISSION_EDIT, page)"
            :disabled="!hasEditPermission('permission_settings') || isUnavailableAccessType(PERMISSION_EDIT, page)"
            @change="changePermission(PERMISSION_EDIT, page)"
          )
        .permissions-table-cell.col-sm-3.d-flex.justify-content-center.align-items-center
          AppCheckbox.highlight-checked(
            :value="isSelected(PERMISSION_READ, page)"
            :disabled="!hasEditPermission('permission_settings') || isUnavailableAccessType(PERMISSION_READ, page)"
            @change="changePermission(PERMISSION_READ, page)"
          )
        .permissions-table-cell.col-sm-3.d-flex.justify-content-center.align-items-center
          AppCheckbox.highlight-checked(
            :value="isSelected(PERMISSION_DENY, page)"
            :disabled="!hasEditPermission('permission_settings') || isUnavailableAccessType(PERMISSION_DENY, page)"
            @change="changePermission(PERMISSION_DENY, page)"
          )
</template>

<script>
  // mixins
  import withPermissions from "@/mixins/withPermissions"

  // misc
  import { isArray, includes } from "lodash-es"
  import { mapState } from "vuex"
  import { PERMISSION_READ, PERMISSION_EDIT, PERMISSION_DENY, PERMISSION_PAGES_ORDER } from "@/config/constants"

  export default {
    props: {
      selectedPermissions: {
        type: Object,
        default: () => new Object()
      }
    },

    data() {
      return {
        PERMISSION_EDIT,
        PERMISSION_READ,
        PERMISSION_DENY
      }
    },

    components: {
      AppCheckbox: () => import("@/components/elements/AppCheckbox")
    },

    mixins: [withPermissions],

    computed: {
      ...mapState(["pages"]),

      unavailableAccessTypes({ inventoryAccessTypes }) {
        return {
          notifications: PERMISSION_READ,
          inventory_notification_settings: PERMISSION_READ,
          logs: PERMISSION_READ,
          create_account: PERMISSION_READ,
          plan_settings: PERMISSION_READ,
          competitor_prices: PERMISSION_EDIT,
          siffd: inventoryAccessTypes,
          siflp: inventoryAccessTypes,
          manual_inventory_import: inventoryAccessTypes,
          resend_inventory: inventoryAccessTypes
        }
      },

      orderedPages({ pages }) {
        return pages.sort((a, b) => {
          return PERMISSION_PAGES_ORDER.indexOf(a.name) - PERMISSION_PAGES_ORDER.indexOf(b.name)
        })
      },

      inventoryAccessTypes({ selectedPermissions }) {
        return selectedPermissions["inventory_management"] == PERMISSION_EDIT
          ? PERMISSION_READ
          : [PERMISSION_READ, PERMISSION_EDIT]
      }
    },

    methods: {
      page(pageName) {
        return this.pages.find(({ name }) => name === pageName)
      },

      isSelected(type, { name }) {
        const selected = this.selectedPermissions[name]

        return (!selected && type === PERMISSION_DENY) || (selected && type === selected)
      },

      isUnavailableAccessType(accessType, { name }) {
        const unavailablePageAccessTypes = this.unavailableAccessTypes[name] || []

        if (isArray(unavailablePageAccessTypes)) {
          return includes(unavailablePageAccessTypes, accessType)
        } else {
          return unavailablePageAccessTypes === accessType
        }
      },

      changePermission(access_type, page) {
        this.$emit("change", { page: page, access_type })

        if (page.name === "inventory_management" && access_type !== PERMISSION_EDIT) {
          this.$emit("change", { page: this.page("siffd"), access_type: PERMISSION_DENY })
          this.$emit("change", { page: this.page("siflp"), access_type: PERMISSION_DENY })
          this.$emit("change", { page: this.page("manual_inventory_import"), access_type: PERMISSION_DENY })
          this.$emit("change", { page: this.page("resend_inventory"), access_type: PERMISSION_DENY })
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/tables/custom.sass"

  .permissions-table
    $table-selector: &

    &-header
      background: $default-purple-light
      border-bottom: 1px solid $default-purple-light-line
      border-top: 1px solid $default-purple-light-line
      color: $default-purple
      font-weight: bold
      height: 50px

    &-cell
      align-items: center
      border-left: 1px solid $default-purple-light-line
      display: flex
      font-size: 0.8rem
      padding: 5px 10px

      &:last-child
        border-right: 1px solid $default-purple-light-line

    &-item
      #{$table-selector}-cell
        border-bottom: 1px solid $default-purple-light-line

      #{$table-selector}-cell
        font-weight: 600

  @media (max-width: 992px)
    .permissions-table-cell
      font-size: 0.6rem
</style>
